<template>
  <div class="m-readmore">
    <div class="m-readmore-back" @click="handleGo">返回</div>
    <div class="m-readmore-cont">
      <div class="m-readmore-cont-title">
        {{title}}
      </div>
      <div class="m-readmore-cont-time">{{create_time_str}}</div>
      <div class="m-readmore-cont-news">
        <div v-html="new_content"></div>
        <div v-if="fileList.length > 0">
            <div>附件下载</div>
            <div>
              <div v-for="item in fileList" :key="item.attachments_id" class="download" @click="handleDownload(item)">
                <span>{{item.name}}</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/utils/request/config'
import { useRoute, useRouter } from 'vue-router'
import { GetNewsById, GetAttactmentByBusId } from '@/api/WsIndexDatasApi'
import { reactive, toRefs, ref } from 'vue'

const initEffect = (detailsId) => {
  const target = reactive({
    brief_introduction: '',
    create_time_str: '',
    file_url: '',
    new_content: '',
    title: ''
  })
  const init = async () => {
    const { data: { Data } } = await GetNewsById({ QueryId: detailsId })
    console.log('%c 🍩 Data: ', 'font-size:20px;background-color: #ED9EC7;color:#fff;', Data)
    Object.keys(target).forEach(key => {
      target[key] = Data[key]
    })
    target.file_url = config.hostUrl + Data.file_url
  }
  init()
  return { target }
}
const fileEffect = (detailsId) => {
  const fileList = ref([])
  const initFile = async () => {
    const { data: { Data } } = await GetAttactmentByBusId({ QueryId: detailsId })
    Data.forEach(item => {
      fileList.value.push(item)
    })
  }
  initFile()
  return { fileList }
}
export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { detailsId } = route.query
    const { target } = initEffect(detailsId)
    const { fileList } = fileEffect(detailsId)
    const handleGo = () => {
      router.go(-1)
    }
    const handleDownload = (file) => {
      window.open(`${config.hostUrl}/api/Login/Download?token=&attid=${file.attachments_id}`, '_self')
    }
    return { ...toRefs(target), handleGo, fileList, handleDownload }
  }
}
</script>

<style lang="scss" scoped>
.m-readmore {
  padding: .52rem 12vw .78rem;
  box-sizing: content-box;
  position: relative;
  min-height: calc(100vh - 1.01rem);
  max-width: 19.2rem;
  margin: auto;
  &-back {
    margin-left: -.5rem;
    cursor: pointer;
    color: #333;
    &:before {
      content: "← ";
      display: inline-block;
      color: #659cf7;
      white-space: pre;
    }
    &:hover {
      color: #659cf7;
    }
  }
  &-cont {
    margin-top: .54rem;
    &-title {
      margin-bottom: .1rem;
      font-size: .48rem;
      font-family: "Gilroy-Bold",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
      font-weight: 700;
      line-height: 1.41;
    }
    &-time {
      margin-bottom: .3rem;
      color: #828282;
      font-size: .14rem;
      visibility: visible;
    }
    &-news {
      p {
        margin-bottom: .24rem;
      }
      div {
        margin-bottom: .24rem;
      }
    }
  }
}
.download {
  cursor: pointer;
  line-height: 30px;
  text-decoration: underline;
}
</style>
